/*
 * Types
 */
const CLOSE = 'menu/CLOSE';
const TOGGLE = 'menu/TOGGLE';

/*
 * Reducer
 */
const initState = {
  isOpen: false,
};

export default (state = initState, { type }) => {
  switch (type) {
    case CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    default:
      return state;
  }
};

export const menuClose = () => ({
  type: CLOSE,
});

/*
 * Actions
 */
export const menuToggle = () => ({
  type: TOGGLE,
});
