import './Heading.scss';

import cx from 'classnames';
import React from 'react';

const Heading = ({ children, className }) => {
  return <div className={cx('heading', className)}>{children}</div>;
};

export default Heading;
