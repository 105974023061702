// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-case-study-page-index-js": () => import("./../../../src/templates/CaseStudyPage/index.js" /* webpackChunkName: "component---src-templates-case-study-page-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/HomePage/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-our-process-page-index-js": () => import("./../../../src/templates/OurProcessPage/index.js" /* webpackChunkName: "component---src-templates-our-process-page-index-js" */),
  "component---src-templates-our-team-page-index-js": () => import("./../../../src/templates/OurTeamPage/index.js" /* webpackChunkName: "component---src-templates-our-team-page-index-js" */),
  "component---src-templates-our-work-page-index-js": () => import("./../../../src/templates/OurWorkPage/index.js" /* webpackChunkName: "component---src-templates-our-work-page-index-js" */)
}

