import './styles/main.scss';
import './App.scss';

import cx from 'classnames';
import { ScrollToPlugin as GSAPScrollToPlugin, ScrollTrigger as GSAPScrollTrigger, gsap } from 'gsap/all';
import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import TopBar from './components/TopBar/TopBar';
import { md } from './styles/variables/_breakpoints.scss';
import { PageIsMobileCtx, PageScrollUpCtx, PageYOffsetCtx } from './utils/context';
import usePrevious from './utils/usePrevious';

gsap.registerPlugin(GSAPScrollToPlugin, GSAPScrollTrigger);

const App = ({ children, menuIsOpen, pageContext: { routes, messages, lang } }) => {
  const [pageIsMobile, setPageIsMobile] = useState(false);
  const [pageScrollUp, setPageScrollUp] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);

  const prevPageYOffset = usePrevious(pageYOffset);

  // Handling app (body) className change
  useLayoutEffect(() => {
    document.body.className = cx('app', {
      'app--menu-open': menuIsOpen,
    });
  }, [menuIsOpen]);

  // Handling scroll listening
  useLayoutEffect(() => {
    const handleResize = () => {
      setPageIsMobile(document.documentElement.clientWidth < parseInt(md, 10));
    };

    const handleScroll = () => {
      setPageYOffset(window.pageYOffset);
    };

    // init
    handleResize();
    handleScroll();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Handling scroll direction
  useLayoutEffect(() => {
    setPageScrollUp(prevPageYOffset > pageYOffset);
  }, [pageYOffset]);

  return (
    <IntlProvider messages={messages} locale={lang}>
      <PageIsMobileCtx.Provider value={pageIsMobile}>
        <PageScrollUpCtx.Provider value={pageScrollUp}>
          <PageYOffsetCtx.Provider value={pageYOffset}>
            <Helmet>
              <title>BAD Marketing</title>
            </Helmet>
            {lang && <TopBar routes={routes} lang={lang} />}
            <Main lang={lang}>{children}</Main>
            {lang && <Footer lang={lang} />}
          </PageYOffsetCtx.Provider>
        </PageScrollUpCtx.Provider>
      </PageIsMobileCtx.Provider>
    </IntlProvider>
  );
};

export default connect((state) => ({
  menuIsOpen: state.menu.isOpen,
}))(App);
