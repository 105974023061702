import './Form.scss';

import cx from 'classnames';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import extractErrors from '../../utils/validation/extractErrors';
import getSchema from '../../utils/validation/schema';
import { OvalButton } from '../Button/Button';

const getInputFields = (intl) => [
  {
    name: 'secret',
    placeholder: '',
    isOptional: false,
    /* Honeypot field
    "Honeypot" fields are hidden form fields that lure bot users 
    into completing a field that human users can't see.
    https://docs.netlify.com/forms/spam-filters/#honeypot-field
    */
  },
  {
    name: 'name',
    placeholder: intl.formatMessage({ id: 'form.full_name' }),
    isOptional: false,
  },
  {
    name: 'email',
    placeholder: intl.formatMessage({ id: 'form.email_address' }),
    isOptional: false,
  },
  {
    name: 'phone',
    placeholder: intl.formatMessage({ id: 'form.phone_number' }),
    isOptional: true,
    optional: intl.formatMessage({ id: 'form.optional' }),
  },
  {
    name: 'company',
    placeholder: intl.formatMessage({ id: 'form.your_company' }),
    isOptional: false,
  },
  {
    name: 'website',
    placeholder: intl.formatMessage({ id: 'form.company_site' }),
    isOptional: true,
    optional: intl.formatMessage({ id: 'form.if_have' }),
  },
];

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const initialState = {
  name: '',
  email: '',
  phone: '',
  company: '',
  website: '',
  message: '',
};

const reducer = (state, { name, reset, value }) => (reset ? { ...initialState } : { ...state, [name]: value });

const Form = ({ prefix, lang }) => {
  const intl = useIntl();
  const schema = getSchema(intl);
  const inputFields = getInputFields(intl);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const formElem = useRef(null);
  const formErrorKeys = Object.keys(formErrors);

  const formName = prefix ? `${prefix} Contact` : `Contact`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await schema.validate(state, { abortEarly: false });

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': formElem.current.getAttribute('name'),
          ...{ ...state, language: lang },
        }),
      })
        .then(() => {
          dispatch({ reset: true });
          setFormErrors({});
          setError('');
          setSuccess(true);
        })
        .catch((err) => {
          console.info('error', err); // eslint-disable-line
          setFormErrors({});
          setError(intl.formatMessage({ id: 'form.error' }));
        });
    } catch (err) {
      console.info('form error', extractErrors(err)); // eslint-disable-line
      setFormErrors(extractErrors(err));
    }
  };

  useEffect(() => {
    if (!success) {
      return;
    }

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);

  if (error) {
    return (
      <div className="form">
        <div className="form__error" dangerouslySetInnerHTML={{ __html: error }} />
        <OvalButton onClick={() => setError('')}>
          <FormattedMessage id="common.back" />
        </OvalButton>
      </div>
    );
  }

  return (
    <form
      className="form"
      data-netlify="true"
      data-netlify-honeypot="secret"
      method="POST"
      name={formName}
      onSubmit={handleSubmit}
      ref={formElem}
    >
      <input id="language" name="language" type="hidden" value={lang} />
      {inputFields.map(({ name, placeholder, isOptional, optional }) => {
        const fieldName = prefix ? `${prefix}_${name}` : name;
        const fieldCx = cx('form__field-input', {
          'form__field-input--error': formErrors[name],
        });
        return (
          <div key={fieldName} className={cx('form__field', `form__field--${name}`)}>
            <input
              id={fieldName}
              className={fieldCx}
              name={name}
              onChange={({ target: { value } }) => dispatch({ name, value })}
              placeholder={placeholder}
              type="text"
              value={state[name]}
            />
            {isOptional && <span className="form__field-optional">{optional}</span>}
          </div>
        );
      })}
      <textarea
        id={prefix ? `${prefix}_message` : 'message'}
        className={cx('form__textarea', {
          'form__textarea--error': formErrors.message,
        })}
        name="message"
        onChange={({ target: { value } }) => dispatch({ name: 'message', value })}
        placeholder={intl.formatMessage({ id: 'form.help' })}
        value={state.message}
      />
      {!!formErrorKeys.length && (
        <div className="form__error-list">
          <ul>
            {formErrorKeys.map((errKey) => (
              <li key={errKey}>{`${formErrors[errKey]}`}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="form__action">
        <OvalButton type="submit">
          <FormattedMessage id="form.submit" />
        </OvalButton>
      </div>
      {success && (
        <div className="form__success">
          <div>
            <FormattedMessage id="form.success" />
            <br />
            <br />
          </div>
          <div>
            <FormattedMessage id="form.cant_wait_note" />
          </div>
        </div>
      )}
    </form>
  );
};

export default Form;
