import './Footer.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Form from '../Form/Form';
import Heading from '../Heading/Heading';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

const Footer = ({ lang }) => {
  const intl = useIntl();

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__content">
          <div className="footer__left">
            <Heading>
              <h1>{intl.formatMessage({ id: 'menu.lets_chat' })}</h1>
            </Heading>
          </div>
          <div className="footer__right">
            <Form lang={lang} />
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__info">
            <span>
              <FormattedMessage id="footer.copy" />
            </span>
            <a href={intl.formatMessage({ id: 'footer.privacy_policy_url' })} target="_blank" rel="noreferrer">
              <FormattedMessage id="footer.privacy_policy" />
            </a>
          </div>
          <div>
            <LanguageSelector lang={lang} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
