import './Figure.scss';

import cx from 'classnames';
import React from 'react';

const Figure = ({ type }) => {
  const rootCx = cx('figure', {
    [`figure--type-${type}`]: type,
  });

  return <span className={rootCx} />;
};

export default Figure;
