import './TopBar.scss';

import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import LogoSrc from '../../images/bad-marketing-logo.svg';
import { usePageScrollUp, usePageYOffset } from '../../utils/context';
import Menu from '../Menu/Menu';
import MenuBtn from '../MenuBtn/MenuBtn';

const TopBar = ({ menuIsOpen, routes, lang }) => {
  const [disappeared, setDisappeared] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const [transOn, setTransOn] = useState(false);
  const [shouldShowMenu, setShouldShowMenu] = useState(false);

  const pageScrollUp = usePageScrollUp();
  const pageYOffset = usePageYOffset();

  const topBarElem = useRef(null);

  const rootCx = cx('top-bar', {
    'top-bar--disappeared': disappeared,
    'top-bar--show': shouldShowMenu,
    'top-bar--tight': isOut,
    'top-bar--trans-on': transOn,
  });

  useLayoutEffect(() => {
    if (menuIsOpen) {
      return;
    }

    // handle iOS Safari bounce effect when reaching bottom of the page
    setShouldShowMenu(pageScrollUp && window.document.body.offsetHeight - window.innerHeight > pageYOffset);
  }, [menuIsOpen, pageScrollUp, pageYOffset]);

  // Handling page scroll
  useLayoutEffect(() => {
    if (menuIsOpen) {
      return;
    }

    // reset (reaches top of the page)
    if (pageYOffset === 0) {
      setDisappeared(false);
      setIsOut(false);
      return;
    }

    const topBarRect = topBarElem.current.getBoundingClientRect();
    setIsOut(pageYOffset > topBarRect.height);
  }, [menuIsOpen, pageYOffset]);

  // Handling disappeared
  useLayoutEffect(() => {
    // impportant to check if its already out,
    // once out should stay fixed till reaches top of the page (reset)
    if (!isOut || disappeared) {
      return;
    }

    setDisappeared(true);
  }, [isOut]);

  // Handling transition on / off
  useEffect(() => {
    // without this, when scrolling down there would be
    // a wrong transition between default and disappeared states
    // https://blog.logrocket.com/useeffect-vs-uselayouteffect/
    setTransOn(disappeared);
  }, [disappeared]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <header className={rootCx} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={topBarElem}>
      <div className="top-bar__inner">
        <Link className="top-bar__logo-link" to={routes.home.prefix}>
          <img alt="BAD Marketing logo" className="top-bar__logo-img" src={LogoSrc} />
        </Link>
        <MenuBtn isHover={isHover} />
      </div>
      <Menu routes={routes} lang={lang} />
    </header>
  );
};

export default connect((state) => ({
  menuIsOpen: state.menu.isOpen,
}))(TopBar);
