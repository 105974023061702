import './Button.scss';

import cx from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

import Figure from '../Figure/Figure';

const Button = ({ children, figure, isOval, tag, transition, ...props }) => {
  const RootTag = tag || 'button';
  const rootCx = cx('button', {
    'button--oval': isOval,
    [`button--trans-${transition}`]: transition,
  });

  return (
    <RootTag className={rootCx} {...props}>
      {figure && <Figure type={figure} />}
      <span className="button__text">{children}</span>
    </RootTag>
  );
};

export const CTACircleLinkButton = (props) => <Button figure="circle" transition="slide" tag={GatsbyLink} {...props} />;
export const CTASquareLinkButton = (props) => <Button figure="square" transition="slide" tag={GatsbyLink} {...props} />;
export const LinkButton = (props) => <Button tag={GatsbyLink} {...props} />;
export const ListButton = (props) => <Button figure="circle" transition="pop" {...props} />;
export const OvalButton = (props) => <Button isOval {...props} />;
export const OvalLinkButton = (props) => <Button isOval tag={GatsbyLink} {...props} />;

export default Button;
