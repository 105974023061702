import './MenuBtn.scss';

import cx from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { menuToggle } from '../../state/menu';
import Figure from '../Figure/Figure';

const MenuBtn = ({ isHover, menuIsOpen, onClick }) => {
  const intl = useIntl();
  const isActive = isHover || menuIsOpen;

  const rootCx = cx('menu-btn', {
    'menu-btn--active': isActive,
  });

  return (
    <button className={rootCx} onClick={onClick} type="button">
      <span className="menu-btn__text">{intl.formatMessage({ id: `menu.${menuIsOpen ? 'close' : 'menu'}` })}</span>
      <Figure type="circle" />
      <Figure type="square" />
    </button>
  );
};

export default connect(
  (state) => ({ menuIsOpen: state.menu.isOpen }),
  (dispatch) => ({
    onClick: () => {
      dispatch(menuToggle());
    },
  })
)(MenuBtn);
