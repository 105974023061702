import './LanguageSelector.scss';

import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

const LanguageSelector = ({ lang }) => {
  const enClass = cx('language-selector-item', {
    'language-selector-item--active': lang === 'en-gb',
  });

  const koClass = cx('language-selector', {
    'language-selector-item--active language-selector-item--ko': lang === 'ko-kr',
  });

  return (
    <React.Fragment>
      <Link className={enClass} to="/">
        ENG
      </Link>
      &nbsp;/&nbsp;
      <Link className={koClass} to="/ko">
        한국어
      </Link>
    </React.Fragment>
  );
};

export default LanguageSelector;
