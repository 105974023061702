import * as yup from 'yup'; // for everything

/*
  phone validation wasn't specified, switching off.

  const PHONE_NUMBER_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  phone: yup.string().matches(PHONE_NUMBER_REGEX, { message: 'Phone number is not valid', excludeEmptyString: true })
  */

const getSchema = (intl) =>
  yup.object().shape({
    name: yup.string().required(intl.formatMessage({ id: 'validation.name_required' })),
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'validation.email_invalid' }))
      .required(intl.formatMessage({ id: 'validation.email_required' })),
    phone: yup.string(),
    company: yup.string().required(intl.formatMessage({ id: 'validation.company_required' })),
    website: yup.string(),
    message: yup.string().required(intl.formatMessage({ id: 'validation.message_required' })),
  });

export default getSchema;
