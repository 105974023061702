import './Menu.scss';

import cx from 'classnames';
import { Link } from 'gatsby';
import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { menuClose } from '../../state/menu';
import Form from '../Form/Form';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

const getLinks = (routes, intl) => [
  { to: routes.ourProcess.prefix, text: intl.formatMessage({ id: 'menu.our_process' }) },
  { to: routes.ourWork.prefix, text: intl.formatMessage({ id: 'menu.our_work' }) },
  { to: routes.ourTeam.prefix, text: intl.formatMessage({ id: 'menu.our_team' }) },
];

const FADE_ANIMATION_DURATION = 300;

const fadeOutAnimation = (element, callback = () => {}) => {
  const menuTL = gsap.timeline();

  menuTL.to(element, {
    opacity: 0,
    duration: FADE_ANIMATION_DURATION / 1000,
    onComplete: () => {
      menuTL.kill();
      callback();
    },
  });
};

const fadeInAnimation = (element) => {
  const formTL = gsap.timeline();

  formTL.fromTo(
    element,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: FADE_ANIMATION_DURATION / 1000,
      onComplete: () => {
        formTL.kill();
      },
    }
  );
};

const Menu = ({ menuIsOpen, onItemLinkClick, routes, lang }) => {
  const intl = useIntl();
  const links = getLinks(routes, intl);
  const menuRef = useRef(null);
  const formRef = useRef(null);
  const languagesRef = useRef(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (!menuIsOpen) return;

    // hide form on menu open event
    setShowForm(false);
  }, [menuIsOpen]);

  useEffect(() => {
    if (!showForm || !formRef) return;

    fadeInAnimation(formRef.current);
    fadeInAnimation(languagesRef.current);
  }, [showForm, formRef]);

  const handleFormOpenClick = () => {
    fadeOutAnimation(languagesRef.current);
    fadeOutAnimation(menuRef.current, () => setShowForm(true));
  };

  return (
    <div className={cx('menu', { 'menu--open': menuIsOpen })}>
      <div className="menu__inner">
        {showForm ? (
          <div className="menu__form" ref={formRef}>
            <div className="heading menu__title">
              <h1>{intl.formatMessage({ id: 'menu.lets_chat' })}</h1>
            </div>
            <Form lang={lang} />
          </div>
        ) : (
          <React.Fragment>
            <div className="menu__languages" ref={languagesRef}>
              <LanguageSelector lang={lang} />
            </div>
            <nav ref={menuRef}>
              <ul className="menu__list">
                {links.map(({ to, text }) => (
                  <li key={to} className="menu__item">
                    <Link onClick={onItemLinkClick} to={to}>
                      {text}
                    </Link>
                  </li>
                ))}
                <li className="menu__item">
                  <button className="menu__button" type="button" onClick={handleFormOpenClick}>
                    {intl.formatMessage({ id: 'menu.lets_chat' })}
                  </button>
                </li>
              </ul>
            </nav>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    menuIsOpen: state.menu.isOpen,
  }),
  (dispatch) => ({
    onItemLinkClick: () => {
      dispatch(menuClose());
    },
  })
)(Menu);
