import './Main.scss';

import cx from 'classnames';
import React from 'react';

const Main = ({ children, lang }) => {
  const mainClass = cx('main', {
    'ko-lang': lang === 'ko-kr',
  });

  return <main className={mainClass}>{children}</main>;
};

export default Main;
