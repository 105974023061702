const extractErrors = (error) => {
  const { inner = [] } = error || {};
  if (!inner.length) {
    return error.message;
  }

  return inner.reduce((acc, err) => {
    return { ...acc, [err.path]: err.message };
  }, {});
};

export default extractErrors;
